/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";

// Theme customization
@import "theme/theme";

html {
  height: 100%;
}

body {
  height: 100%;
  background: #ededed;
}

header {
  padding: 10px;
}

.p-inputtext {
  width: 100%;
}

.p-password {
  width: 100%;
}
